.noty_body {
    font-family: sans-serif;
    line-height: 1.5em;
    color: #000;
}

.noty_body code {
    background-color: rgba(255, 255, 255, 0.6);
    word-break: break-word;
    border-radius: 4px;
    padding: 2px 4px;
    font-size: 0.9em;
}

.noty-action {
    margin-bottom: 4px;
    font-weight: bold;
}
